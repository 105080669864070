import { AxiosResponse } from 'axios'
import { News } from 'src/types/news'

import axios from '../../axios'
import { NewsRequestBody } from './createNews'

export const updateNews = async (
  id: string,
  requestBody: NewsRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .put<{ data: News }>(`/api/news/${id}`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
