import { AxiosResponse } from 'axios'
import { NotificationCreateResponse } from 'src/types/notification'

import axios from '../../axios'

export type NotificationCreateRequestBody = {
  title: string
  content: string
  files: File[]
  agencyCodeList: string[]
}

export const createNotification = async (
  requestBody: NotificationCreateRequestBody
): Promise<AxiosResponse> => {
  const Data = new FormData()
  Data.append('title', requestBody.title)
  Data.append('content', requestBody.content)
  for (let i = 0; i < requestBody.files.length; i++) {
    const file = requestBody.files[i]
    Data.append('files[' + i + ']', file)
  }
  for (let i = 0; i < requestBody.agencyCodeList.length; i++) {
    const agencyCode = requestBody.agencyCodeList[i]
    Data.append('agencyCodeList[' + i + ']', agencyCode)
  }
  const headers = {
    'content-type': 'multipart/form-data',
  }
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: NotificationCreateResponse }>(`/api/notifications`, Data, {
        headers,
      })
      .catch((error) => {
        return error.response
      })
    return response
  })
}
