import { AxiosResponse } from 'axios'
import { News } from 'src/types/news'

import axios from '../../axios'

export const fetchNewsList = async (
  isAdminRequest: boolean
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: News[] }>('/api/news', {
      params: { isAdminRequest },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
