import { AxiosResponse } from 'axios'
import { PostNews } from 'src/types/news'

import axios from '../../axios'

export type NewsRequestBody = {
  title: string
  content: string
}

export const createNews = async (
  requestBody: NewsRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: PostNews }>(`/api/news`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
