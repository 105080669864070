import { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createApiClient } from 'src/lib/api'
import { useDispatch } from 'src/store'
import {
  initialize,
  updateToAgencyCodeInSessionStorage,
} from 'src/store/slices/auth'
import { User } from 'src/types/user'
import { needPhoneNumberVerification } from 'src/utils/user'

type Props = {
  children: ReactNode
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const apiClient = createApiClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // 初期認証
  useEffect(() => {
    const authInitialize = async (): Promise<void> => {
      const response = await apiClient.auth.me()

      if (response.status === 200) {
        if (response.data.user) {
          // プラスユーザーの場合は代理店を選択する際に代理店コードを保持する
          // リロード時にリセットされないようにsessionStorageに保持しているので、都度代理店コードを更新させる
          const user = updateToAgencyCodeInSessionStorage(
            response.data.user as User
          )
          const isEmailVerified = user.isEmailVerified
          const isPhoneNumberVerified = !needPhoneNumberVerification(user)

          if (isEmailVerified && isPhoneNumberVerified) {
            dispatch(
              initialize({
                isAuthenticated: true,
                user,
              })
            )
          } else {
            dispatch(
              initialize({
                isAuthenticated: false,
                user,
              })
            )

            // メールアドレスと電話番号の検証が必要（emailの検証が優先）
            if (!user.isEmailVerified) {
              navigate('/authentication/verify-email')
              return
            }
            if (!isPhoneNumberVerified) {
              navigate('/authentication/verify-phone-number')
            }
          }
        }
      } else {
        dispatch(
          initialize({
            isAuthenticated: false,
            user: null,
          })
        )
      }
    }

    authInitialize()
  }, [apiClient.auth, dispatch, navigate])

  return <>{children}</>
}
