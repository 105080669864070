import { AxiosResponse } from 'axios'
import { PlusUser } from 'src/types/plusUser'
import { PlusUserRoleType } from 'src/types/roleType'

import axios from '../../axios'

export type PlusUserUpdateRequestBody = {
  name: string
  phoneNumber?: string
  roleType: PlusUserRoleType
  isMfaActive: boolean
}

export const updatePlusUser = async (
  id: string,
  requestBody: PlusUserUpdateRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .put<{ data: PlusUser }>(`/api/plus-users/${id}`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
