export const PLUS_SUPER_ADMIN = 'plus_super_admin'
export const PLUS_ADMIN = 'plus_admin'
export const PLUS_GENERAL = 'plus_general'
export const RECRUITER_ADMIN = 'recruiter_admin'
export const RECRUITER_GENERAL = 'recruiter_general'

export type PlusUserRoleType =
  | typeof PLUS_SUPER_ADMIN
  | typeof PLUS_ADMIN
  | typeof PLUS_GENERAL
export type RecruiterRoleType =
  | typeof RECRUITER_ADMIN
  | typeof RECRUITER_GENERAL
export type RoleType = PlusUserRoleType | RecruiterRoleType

export const roleTypes = {
  [PLUS_GENERAL]: 'プラス一般',
  [PLUS_ADMIN]: 'プラス管理者',
  [PLUS_SUPER_ADMIN]: 'プラス特権管理者',
  [RECRUITER_GENERAL]: '募集人一般',
  [RECRUITER_ADMIN]: '募集人管理者',
} as const

export type RoleLabel = (typeof roleTypes)[keyof typeof roleTypes]

type RoleTypeLabel = {
  label: RoleLabel
  value: RoleType
}

type PlusRoleTypeLabel = {
  label: string
  value: PlusUserRoleType
}

type RecruiterRoleTypeLabel = {
  label: string
  value: RecruiterRoleType
}

export const roleTypeLabel: RoleTypeLabel[] = [
  {
    label: 'プラス一般',
    value: PLUS_GENERAL,
  },
  {
    label: 'プラス管理者',
    value: PLUS_ADMIN,
  },
  {
    label: 'プラス特権管理者',
    value: PLUS_SUPER_ADMIN,
  },
  {
    label: '募集人一般',
    value: RECRUITER_GENERAL,
  },
  {
    label: '募集人管理者',
    value: RECRUITER_ADMIN,
  },
]

export const plusRoleTypeLabel: PlusRoleTypeLabel[] = [
  {
    label: '一般',
    value: PLUS_GENERAL,
  },
  {
    label: '管理者',
    value: PLUS_ADMIN,
  },
  {
    label: '特権管理者',
    value: PLUS_SUPER_ADMIN,
  },
]

export const recruiterRoleTypeLabel: RecruiterRoleTypeLabel[] = [
  {
    label: '一般',
    value: RECRUITER_GENERAL,
  },
  {
    label: '管理者',
    value: RECRUITER_ADMIN,
  },
]

export const isPlusUserRole = (
  roleType: RoleType
): roleType is PlusUserRoleType => {
  return (
    roleType === PLUS_SUPER_ADMIN ||
    roleType === PLUS_ADMIN ||
    roleType === PLUS_GENERAL
  )
}
