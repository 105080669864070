import type { AppBarProps } from '@material-ui/core'
import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import { experimentalStyled, makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'src/hooks/useAuth'

import MenuIcon from '../../icons/Menu'
import AccountSettings from '../authentication/AccountSettings'
import ManualButton from './ManualButton'

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void
}

const useStyles = makeStyles({
  logo: {
    display: 'block',
  },
})

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  // ロゴの背景が白なので、とりあえず背景を白にした。
  backgroundColor: '#fff',
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 100,
}))

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props
  const classes = useStyles()
  const { logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogout = async (): Promise<void> => {
    try {
      await logout()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      })
    }
  }

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton color="primary" onClick={onSidebarMobileOpen}>
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <img
              src="/static/logo.png"
              alt="logo"
              height="48"
              className={classes.logo}
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box>
          <ManualButton />
        </Box>
        <Box>
          <AccountSettings />
        </Box>
        <Box sx={{ p: 2, ml: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

export default DashboardNavbar
