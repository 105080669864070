import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const destroyNotification = async (
  id: string
): Promise<AxiosResponse> => {
  const response = await axios
    .delete(`/api/notifications/${id}`)
    .catch((error) => {
      return error.response
    })

  return response
}
