import {
  PLUS_ADMIN,
  PLUS_GENERAL,
  PLUS_SUPER_ADMIN,
  RECRUITER_ADMIN,
} from 'src/types/roleType'
import { LOGIN_USER_STATUS, LoginUserStatus } from 'src/types/user'

import { useAuth } from './useAuth'

const useLoginUserStatus = (): LoginUserStatus | undefined => {
  const { user } = useAuth()
  if (!user) {
    return
  }

  const userRole = user.roleType

  if (userRole === PLUS_SUPER_ADMIN) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.PLUS_SUPER_ADMIN_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.PLUS_SUPER_ADMIN
    }
  }

  if (userRole === PLUS_ADMIN) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.PLUS_ADMIN_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.PLUS_ADMIN
    }
  }

  if (userRole === PLUS_GENERAL) {
    if (user.agencyCode) {
      return LOGIN_USER_STATUS.PLUS_GENERAL_SELECTED_AGENCY
    } else {
      return LOGIN_USER_STATUS.PLUS_GENERAL
    }
  }

  if (userRole === RECRUITER_ADMIN) {
    return LOGIN_USER_STATUS.RECRUITER_ADMIN
  } else {
    return LOGIN_USER_STATUS.RECRUITER_GENERAL
  }
}

export default useLoginUserStatus
