import { createPlusUser, PlusUserCreateRequestBody } from './createPlusUser'
import { destroyPlusUser } from './destroyPlusUser'
import { fetchPlusUser } from './fetchPlusUser'
import { fetchPlusUsers } from './fetchPlusUsers'
import { PlusUserUpdateRequestBody, updatePlusUser } from './updatePlusUser'

export const plusUsers = () => {
  return {
    index() {
      return fetchPlusUsers()
    },
    show(id: string) {
      return fetchPlusUser(id)
    },
    post(requestBody: PlusUserCreateRequestBody) {
      return createPlusUser(requestBody)
    },
    put(id: string, requestBody: PlusUserUpdateRequestBody) {
      return updatePlusUser(id, requestBody)
    },
    destroy(id: string) {
      return destroyPlusUser(id)
    },
  }
}
