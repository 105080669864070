import { PlusUser } from './plusUser'
import { Recruiter } from './recruiter'

export type User = PlusUser | Recruiter

// ログイン中のユーザーの種類
export const LOGIN_USER_STATUS = {
  PLUS_SUPER_ADMIN_SELECTED_AGENCY: 'plus_super_admin_selected_agency',
  PLUS_ADMIN_SELECTED_AGENCY: 'plus_admin_selected_agency',
  PLUS_GENERAL_SELECTED_AGENCY: 'plus_general_selected_agency',
  PLUS_SUPER_ADMIN: 'plus_super_admin',
  PLUS_ADMIN: 'plus_admin',
  PLUS_GENERAL: 'plus_general',
  RECRUITER_ADMIN: 'recruiter_admin',
  RECRUITER_GENERAL: 'recruiter_general',
} as const

export type LoginUserStatus =
  (typeof LOGIN_USER_STATUS)[keyof typeof LOGIN_USER_STATUS]
