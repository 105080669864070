import { AxiosResponse } from 'axios'

import axios from '../../axios'

export const destroyPlusUser = async (id: string): Promise<AxiosResponse> => {
  const response = await axios
    .delete(`/api/plus-users/${id}`)
    .catch((error) => {
      return error.response
    })

  return response
}
