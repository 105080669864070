import { AxiosResponse } from 'axios'
import { News } from 'src/types/news'

import axios from '../../axios'

export const fetchNews = async (id: string): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: News }>(`/api/news/${id}`)
    .catch((error) => {
      return error.response
    })

  return response
}
