import { AxiosResponse } from 'axios'
import { NotificationList } from 'src/types/notification'

import axios from '../../axios'

export const fetchNotificationList = async (
  agencyCode: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: NotificationList }>('/api/notifications', {
      params: {
        agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
