import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlusUser } from 'src/types/plusUser'

export type PlusUsersState = {
  plusUsers: PlusUser[]
}

export const initialPlusUsersState: PlusUsersState = {
  plusUsers: [],
}

export const plusUsersSlice = createSlice({
  name: 'plusUsers',
  initialState: initialPlusUsersState,
  reducers: {
    setPlusUsers: (
      state: PlusUsersState,
      action: PayloadAction<PlusUser[]>
    ) => ({
      ...state,
      plusUsers: action.payload,
    }),
    updatePlusUser: (
      state: PlusUsersState,
      action: PayloadAction<PlusUser>
    ) => ({
      ...state,
      plusUsers: state.plusUsers.map((plusUser) =>
        plusUser.id === action.payload.id ? action.payload : plusUser
      ),
    }),
    createPlusUser: (
      state: PlusUsersState,
      action: PayloadAction<PlusUser>
    ) => {
      state.plusUsers.push(action.payload)
    },
    deletePlusUser: (state: PlusUsersState, action: PayloadAction<string>) => ({
      ...state,
      plusUsers: state.plusUsers.filter(
        (plusUser) => plusUser.id !== action.payload
      ),
    }),
  },
})

export const { setPlusUsers, updatePlusUser, createPlusUser, deletePlusUser } =
  plusUsersSlice.actions
