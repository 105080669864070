import { AxiosResponse } from 'axios'
import { NotificationList } from 'src/types/notification'

import axios from '../../axios'

export const fetchRecruiterNotificationList = async (
  agencyCode?: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: NotificationList }>('/api/recruiter-notifications', {
      params: {
        agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
