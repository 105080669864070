import { AxiosResponse } from 'axios'
import { PlusUser } from 'src/types/plusUser'

import axios from '../../axios'

export const fetchPlusUsers = async (): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: PlusUser[] }>('/api/plus-users')
    .catch((error) => {
      return error.response
    })

  return response
}
