import { AxiosResponse } from 'axios'
import { PlusUser } from 'src/types/plusUser'
import { PlusUserRoleType } from 'src/types/roleType'

import axios from '../../axios'

export type PlusUserCreateRequestBody = {
  name: string
  email: string
  phoneNumber?: string
  roleType: PlusUserRoleType
}

export const createPlusUser = async (
  requestBody: PlusUserCreateRequestBody
): Promise<AxiosResponse> => {
  return axios.get(`/api/csrf-cookie`).then(() => {
    const response = axios
      .post<{ data: PlusUser }>(`/api/plus-users`, requestBody)
      .catch((error) => {
        return error.response
      })

    return response
  })
}
