import { AxiosResponse } from 'axios'
import { NotificationDetail } from 'src/types/notification'

import axios from '../../axios'

export const fetchRecruiterNotification = async (
  id: string,
  agencyCode: string
): Promise<AxiosResponse> => {
  const response = await axios
    .get<{ data: NotificationDetail }>(`/api/recruiter-notifications/${id}`, {
      params: {
        agencyCode,
      },
    })
    .catch((error) => {
      return error.response
    })

  return response
}
