import { createNews, NewsRequestBody } from '../news/createNews'
import { fetchNews } from '../news/fetchNews'
import { destroyNews } from './destroyNews'
import { fetchNewsList } from './fetchNewsList'
import { updateNews } from './updateNews'

export const news = () => {
  return {
    index(isAdminRequest?: boolean) {
      return fetchNewsList(isAdminRequest ?? false)
    },
    post(requestBody: NewsRequestBody) {
      return createNews(requestBody)
    },
    show(id: string) {
      return fetchNews(id)
    },
    put(id: string, requestBody: NewsRequestBody) {
      return updateNews(id, requestBody)
    },
    destroy(id: string) {
      return destroyNews(id)
    },
  }
}
