import {
  createNotification,
  NotificationCreateRequestBody,
} from './createNotification'
import { destroyNotification } from './destroyNotification'
import { downloadFile } from './downloadFile'
import { downloadFileRecruiter } from './downloadFileRecruiter'
import { fetchNotification } from './fetchNotification'
import { fetchNotificationList } from './fetchNotificationList'
import { fetchRecruiterNotification } from './fetchRecruiterNotification'
import { fetchRecruiterNotificationList } from './fetchRecruiterNotificationList'

export const notifications = () => {
  return {
    index(agencyCode: string) {
      return fetchNotificationList(agencyCode)
    },
    post(requestBody: NotificationCreateRequestBody) {
      return createNotification(requestBody)
    },
    show(id: string) {
      return fetchNotification(id)
    },
    downloadFile(notificationId: string, filePath: string, agencyCode: string) {
      return downloadFile(notificationId, filePath, agencyCode)
    },
    destroy(id: string) {
      return destroyNotification(id)
    },
    findByAgencyCode(agencyCode?: string) {
      return fetchRecruiterNotificationList(agencyCode)
    },
    showRecruiter(id: string, agencyCode: string) {
      return fetchRecruiterNotification(id, agencyCode)
    },
    downloadFileRecruiter(
      notificationId: string,
      filePath: string,
      agencyCode: string
    ) {
      return downloadFileRecruiter(notificationId, filePath, agencyCode)
    },
  }
}
