import { subDays, subHours } from 'date-fns'

import { mock } from '../lib/axiosMock'
import type { PlusUser } from '../types/plusUser'

const now = new Date()

mock.onGet('/api/plus-users').reply(() => {
  const plusUsers: PlusUser[] = [
    {
      id: '5e887ac47eed253091be10cb',
      email: 'fran.perez@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '山田  一郎',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:34:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      email: 'fran.perez@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '鈴木 二郎',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887b7602bdbc4dbb234b27',
      email: 'jie.yan.song@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '太田 三郎',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e86809283e28b96d2d38537',
      email: 'jane.rotanson@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '前田 四郎',
      roleType: 'plus_admin',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e86805e2bafd54f66cc95c3',
      email: 'miron.vitold@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '小島 花子',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887a1fbefd7938eea9c981',
      email: 'penjani.inyene@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '村田 智子',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e887d0b3d090c1b8f162003',
      email: 'omar.darobe@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '久田 智',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e88792be2d4cfb4bf0971d9',
      email: 'siegbert.gottfried@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '大島 享',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e8877da9a65442b11551975',
      email: 'iulia.albu@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '川口 実',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
    {
      id: '5e8680e60cba5019c5ca6fda',
      email: 'nasimiyu.danai@devias.io',
      isEmailVerified: true,
      phoneNumberStatus: 'unregistered',
      name: '飯田 雅仁',
      roleType: 'plus_general',
      lastLoginAt: '2021/04/14 11:33:33',
      isMfaActive: false,
      createdAt: subDays(subHours(now, 7), 1).getTime(),
      updatedAt: subDays(subHours(now, 7), 1).getTime(),
    },
  ]

  return [200, { plusUsers }]
})

mock.onGet('/api/plus-users/1').reply(() => {
  const plusUser: PlusUser = {
    id: '5e887ac47eed253091be10cb33',
    email: 'fran.perez@devias.io',
    isEmailVerified: true,
    phoneNumberStatus: 'unregistered',
    name: '山田 一郎',
    roleType: 'plus_general',
    lastLoginAt: '2021/04/14 11:34:33',
    isMfaActive: false,
    createdAt: subDays(subHours(now, 7), 1).getTime(),
    updatedAt: subDays(subHours(now, 7), 1).getTime(),
  }

  return [200, { plusUser }]
})
